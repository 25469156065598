<template>
  <div>
    <div v-if="!AddVisible && !EditVisible" class="view-wrap">
      <div class="search">
        <el-form :inline="true">
          <el-form-item class="hiddenFormItem">
            <el-input
              style="width: 100%"
              v-model="searcForm.param"
              placeholder="输入商品名称/编号/登记证号"
            ></el-input>
          </el-form-item>
          <el-form-item label="类别">
            <el-cascader
              v-model="typeId"
              style="width: 100%"
              :props="typeProps"
              :options="typeList"
              @change="handleChange"
              :show-all-levels="false"
              placeholder="请选择类别"
              clearable
            >
              <template slot-scope="{ node, data }">
                <span>{{ data.name }}</span>
              </template>
            </el-cascader>
          </el-form-item>
          <el-form-item label="是否限用">
            <el-select
              v-model="searcForm.isLimit"
              style="width: 100%"
              clearable
              placeholder="是否限用"
            >
              <el-option
                v-for="(item, Index) in limits"
                :key="Index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="毒性">
            <el-select
              v-model="searcForm.toxicity"
              style="width: 100%"
              clearable
              placeholder="毒性"
            >
              <el-option
                v-for="(item, Index) in toxicityList"
                :key="Index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData"
              ><i class="el-icon-search" /> 查询</el-button
            >
            <el-button type="primary" @click="resetSearch"
              ><i class="el-icon-search" /> 清空</el-button
            >
            <!-- <el-button @click="scanCode"
              ><i class="el-icon-full-screen"> 扫码</i></el-button
            > -->
          </el-form-item>
        </el-form>
      </div>
      <div class="button">
        <el-button type="primary" round @click="handleCommand('1')">
          <i class="el-icon-plus" /> 新增</el-button
        >
        <!-- <el-dropdown trigger="click" @command="handleCommand">
          <el-button type="primary" round>
            <i class="el-icon-plus" /> 新增</el-button
          >
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">农药</el-dropdown-item>
            <el-dropdown-item command="2">种子</el-dropdown-item>
            <el-dropdown-item command="3">化肥</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
      <div class="view">
        <el-table
          :data="tableData"
          border
          style="width: 100%; margin-bottom: 20px"
          :highlight-current-row="true"
          :header-cell-style="{
            background: '#F5F6F8',
            color: '#333333',
            'text-align': 'center',
          }"
        >
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                content="修改"
                placement="top-start"
              >
                <el-button type="text" @click="EditData(scope.row)"
                  ><i class="el-icon-edit"></i
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="删除"
                placement="top-start"
              >
                <el-button type="text" @click="deleteData(scope.row)"
                  ><i class="el-icon-delete"></i
                ></el-button>
              </el-tooltip>
              <!-- <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.status ? '启用' : '停用'"
                placement="top-start"
              >
                <el-button type="text" @click="changeStatus(scope.row)"
                  ><i
                    :class="
                      scope.row.status
                        ? 'el-icon-video-play'
                        : 'el-icon-video-pause'
                    "
                  ></i
                ></el-button>
              </el-tooltip> -->
            </template>
          </el-table-column>
          <el-table-column
            prop="code"
            label="编号"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.code ? scope.row.code : "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="商品名称"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>
                <span>{{ scope.row.name ? scope.row.name : "-" }}</span>
                <span v-if="scope.row.isLimit == '1'"
                  ><i
                    style="color: red; font-size: 20px; float: right"
                    class="iconfont icon-chuangkexianzhi"
                  ></i
                ></span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="regCode"
            label="登记证号"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.regCode }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="barCode"
            label="商品条码"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.barCode ? scope.row.barCode : "-" }}</span>
            </template>
          </el-table-column> -->
          <!-- <el-table-column
            prop="barCode"
            label="管理类型"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.isBatch ? "批次管理" : "" }}</span>
              <span>{{ scope.row.isSerial ? "序列码管理" : "" }}</span>
              <span v-if="!scope.row.isBatch && !scope.row.isSerial">普通</span>
            </template>
          </el-table-column> -->
          <el-table-column
            prop="typeId"
            label="商品类别"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ getTypeName(scope.row.typeId) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="netContentUnit"
            label="单位"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ getUnits(scope.row) }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="status"
            label="状态"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.status=='1' ? "停用" : "启用" }}</span>
            </template>
          </el-table-column> -->
          <el-table-column
            prop="status"
            label="净含量"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.netContent">{{
                scope.row.netContent + "/" + scope.row.netContentUnitName
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            align="right"
            label="采购价"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.purchasePrice === undefined"> - </span>
              <span v-else>
                <span v-if="scope.row.purchasePrice">
                  {{ (scope.row.purchasePrice / 100).toFixed(2) }}
                </span>
                <span v-else>{{ "0.00" }}</span>
              </span>
            </template>
          </el-table-column> -->
          <el-table-column
            align="right"
            label="零售价"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.price === undefined"> - </span>
              <span v-else>
                <span v-if="scope.row.price">
                  {{ (scope.row.price / 100).toFixed(2) }}
                </span>
                <span v-else>{{ "0.00" }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="right"
            label="批发价"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.batchPrice === undefined"> - </span>
              <span v-else>
                <span v-if="scope.row.batchPrice">
                  {{ (scope.row.batchPrice / 100).toFixed(2) }}
                </span>
                <span v-else>{{ "0.00" }}</span>
              </span>
            </template>
          </el-table-column>
          <!-- <el-table-column align="right" label="会员价" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.unit[0].memberPrice === undefined"> - </span>
            <span v-else>
                <span v-if="scope.row.unit[0].memberPrice"> {{ scope.row.unit[0].memberPrice | backPrice }} </span>
                <span v-else>{{'0.00'}}</span>
            </span>
          </template>
        </el-table-column> -->
        </el-table>
        <el-pagination
          class="pagination"
          @size-change="changePageSize"
          @current-change="changeCurrentPage"
          :current-page="searcForm.pageNum"
          :page-size="searcForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <AddProduct
      ref="AddProduct"
      v-if="AddVisible"
      @back="backList"
      @refresh="refresh"
    ></AddProduct>
    <EditProduct
      ref="EditProduct"
      v-if="EditVisible"
      @back="backList"
      @refresh="refresh"
    ></EditProduct>
  </div>
</template>
<script>
import {
  GetProductList,
  updateStatus,
  DeleteProduct,
} from "@/api/pesticideProduct";
import { GetProductTypeList } from "@/api/productType";
import AddProduct from "./ProductForm.vue";
import EditProduct from "./EditProductForm.vue";
export default {
  components: { AddProduct, EditProduct },
  data() {
    return {
      typeListName: [],
      searcForm: {
        pageNum: 1,
        pageSize: 10,
        param: "",
        typeId: "",
        isLimit: "",
        toxicity: "",
      },
      pageIndex: 1,
      typeList: [],
      limits: [
        { label: "否", value: "0" },
        { label: "是", value: "1" },
      ],
      toxicityList: [
        {
          value: "全部",
          label: "全部",
        },
        //毒性列表
        {
          value: "剧毒",
          label: "剧毒",
        },
        {
          value: "高毒",
          label: "高毒",
        },
        {
          value: "中等毒",
          label: "中等毒",
        },
        {
          value: "低毒",
          label: "低毒",
        },
        {
          value: "微毒",
          label: "微毒",
        },
      ],
      UnitList: [
        {
          id: "0",
          name: "毫升",
        },
        {
          id: "1",
          name: "克",
        },

        {
          id: "2",
          name: "千克",
        },
        {
          id: "3",
          name: "升",
        },
      ],
      tableData: [],
      total: 0,
      operationEvent: [],
      unit: [],
      typeProps: {
        value: "id",
        label: "name",
        children: "list",
      },
      typeId: [],
      AddVisible: false,
      EditVisible: false,
    };
  },
  activated() {
    this.getData();
    this.getType();
  },
  filters: {
    backPrice(val) {
      let num = val;
      num += "";
      num = num.replace(/[^0-9|\.]/g, "");

      if (/^0+/) num = num.replace(/^0+/, "");
      if (!/\./.test(num)) num += ".00";
      if (/^\./.test(num)) num = "0" + num;
      num += "00";
      num = num.match(/\d+\.\d{2}/)[0];
      return num;
    },
  },
  methods: {
    getUnits(data) {
      let result = data.unit;
      if (data.isMultiUnit == "1") {
        data.unitList.forEach((v) => {
          if (v.isInit == "1") {
            result += "," + v.name + "(1:" + v.value + ")";
          }
        });
      }
      return result;
    },
    getTypeName(val) {
      let typeName = "";

      this.typeListName.forEach((item) => {
        if (val == item.id) {
          typeName = item.name;
        }
      });

      return typeName;
    },
    getType() {
      GetProductTypeList().then((res) => {
        if (res.code === 1) {
          const { records } = res.data;
          this.typeList = records;
          this.typeListName = records[0].list;
          this.getTreeData(this.typeList);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].list.length < 1) {
          // list若为空数组，则将删除
          delete data[i].list;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].list);
        }
      }
      return data;
    },
    handleChange(value) {
      if (value.length !== 0) {
        this.searcForm.typeId = value[value.length - 1];
      } else {
        this.searcForm.typeId = "";
      }
    },
    getData() {
      const result = JSON.parse(JSON.stringify(this.searcForm));
      if (result.toxicity === "全部") {
        delete result.toxicity;
      }
      GetProductList(result).then((res) => {
        if (res.code === 1) {
          const { records, current, total } = res.data;
          this.tableData = records.map((v) => {
            this.UnitList.forEach((u) => {
              if (v.netContentUnit == u.id) {
                v.netContentUnitName = u.name;
              }
            });
            return v;
          });
          this.total = total;
          this.searcForm.pageNum = current;
          // 当没有数据
          if (records.length === 0) {
            // 判断是否为第一页
            if (current === 1) {
              this.searcForm.pageNum = 1;
              this.loading = false;
              return;
            } else {
              // 不是回退再重新搜索
              this.searcForm.pageNum = current - 1;
              this.getData();
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    backList() {
      this.AddVisible = false;
      this.EditVisible = false;
    },
    refresh() {
      this.getData();
    },
    handleCommand(command) {
      if (command == "1") {
        this.AddData();
      }
    },
    // 新增商品
    AddData() {
      this.AddVisible = true;
      // this.$router.push({ path: "/commodity/ProductForm" });
    },
    // 修改商品
    EditData(data) {
      this.EditVisible = true;
      this.$nextTick(() => {
        this.$refs.EditProduct.init(data.id);
      });
    },
    // 分页
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getData();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      this.getData();
    },
    // 启用/停用商品
    changeStatus(data) {
      this.$confirm(`是否${data.status ? "启用" : "禁用"}该商品？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        const form = { id: data.id, status: data.status ? 0 : 1 };
        updateStatus(form).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
          this.getData();
        });
      });
    },
    scanCode() {
      this.$router.push({ name: "ProductForm" });
      sessionStorage.setItem("status", true);
    },
    deleteData(data) {
      this.$confirm(`是否删除该商品？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        DeleteProduct(data.id).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
          this.getData();
        });
      });
    },
    resetSearch() {
      this.searcForm = {
        param: "",
        typeId: "",
        isLimit: "",
        toxicity: "",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.pagination {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
</style>
